<template>
  <v-sheet @ready="displayQuests" dark class="background">
    <div
      style="position: fixed; top: 0; bottom: 0; left: 0; right: 0"
      class="pa-3 d-flex flex-column"
      v-if="quests"
    >
      <v-btn class="mt-6" absolute left icon dark to="/">
        <v-icon dark> mdi-arrow-left </v-icon>
      </v-btn>
      <img
        class="my-6 mx-auto"
        style="max-width: 110px"
        src="/img/Logo.svg"
        alt="Baccarat Quest"
      />
      <div class="mb-6">
        <div
          class="text-center text-h6 text-uppercase font-weight-black"
          v-html="ui('choose')"
        />
      </div>
      <v-container class="flex-grow-1 mb-3" style="overflow: auto">
        <v-slide-y-reverse-transition group class="row">
          <v-col
            class="text-center"
            v-for="quest in questlist"
            :key="quest.id"
            cols="6"
          >
            <v-card
              :to="`/Quest/${quest.id}`"
              color="transparent"
              elevation="0"
            >
              <v-avatar style="border: 3px solid white" size="110">
                <v-img
                  rel="preload"
                  :src="getStrapiMedia(quest.thumbnail.formats.thumbnail.url)"
                  :alt="quest.thumbnail.alternativeText"
                  aspect-ratio="1"
                />
              </v-avatar>
              <div class="text-center font-weight-bold">
                {{ quest.name }}
              </div>
              <div class="text-center text-caption">
                {{ ui("level") }} {{ ui(quest.level) }} ({{
                  progress(quest.id)
                }}%)
              </div>
            </v-card>
          </v-col>
        </v-slide-y-reverse-transition>
      </v-container>
      <v-row class="flex-grow-0">
        <v-col
          v-for="social in socialMedias"
          :key="`div-${social.name}`"
          class="text-center"
          cols="6"
        >
          <a target="_blank" rel="noopener noreferrer" :href="social.link">
            <img
              style="max-width: 40px"
              :src="`/img/${social.name}.svg`"
              :alt="social.name"
            />
          </a>
        </v-col>
      </v-row>
    </div>
    <v-container v-else fill-height>
      <v-progress-circular
        class="mx-auto"
        :size="100"
        :width="5"
        indeterminate
      ></v-progress-circular>
    </v-container>
  </v-sheet>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Home",
  data: () => ({
    socialMedias: [
      {
        name: "Facebook",
        link: "https://www.facebook.com/Heruditatem",
      },
      {
        name: "Instagram",
        link: "https://www.instagram.com/heruditatem/",
      },
    ],
    questlist: [],
  }),
  computed: {
    ...mapState({
      locale: (state) => state.locale.value,
      quests: (state) => state.quest.list,
    }),
    ...mapGetters(["progress"]),
  },
  methods: {
    displayQuests() {
      const delay = 50;
      let time = 0;
      Object.values(this.quests)
        .sort((a, b) => a.Order - b.Order)
        .forEach((quest) => {
          setTimeout(() => this.questlist.push(quest), (time += delay));
        });
    },
    getStrapiMedia(url) {
      // Check if URL is a local path
      if (url.startsWith("/")) {
        // Prepend Strapi address
        return `http://api.baccaratquest.fr${url}`;
      }
      // Otherwise return full URL
      return url;
    },
  },
  watch: {
    quests() {
      this.$nextTick(() => this.displayQuests());
    },
  },
  mounted() {
    if (this.quests) {
      this.displayQuests();
    }
  },
};
</script>

<style>
.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background: url("/img/Background.svg");
  background-size: cover;
  background-position: center center;
}

.pop-in-enter-active {
  transform: translateY(15px);
}

.pop-in-leave-active {
  transition: all 1s ease;
}

.pop-in-enter,
.pop-in-leave-to {
  position: absolute; /* add for smooth transition between elements */
  opacity: 0;
}
</style>
