import Vue from "vue";
import { get_quests } from "@/api/strapi";

const state = {
  list: null,
};

const mutations = {
  SET_QUESTS(state, payload) {
    state.list = {};
    payload.forEach((quest) => {
      quest.steps = quest.steps.sort((a, b) => a.order - b.order);
      Vue.set(state.list, quest.id, quest);
    });
  },
};

const getters = {
  quest: (state, getters, rootState) =>
    state.list ? state.list[rootState.current.quest] : null,
  steps: (state, getters) => getters.quest.steps,
  step: (state, getters) => getters.steps[getters.step_idx],
  steps_locations: (state, getters) =>
    getters.steps.slice(0, getters.step_idx).map((step) => step.location),
};

const actions = {
  async load_quests({ commit, rootState }) {
    try {
      const quests = await get_quests(rootState.locale.value);
      commit("SET_QUESTS", quests);
    } catch (err) {
      // Handle Error Here
      console.error(err);
    }
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
