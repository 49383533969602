import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import QuestSelection from "../views/QuestSelection.vue";
import Quest from "../views/Quest.vue";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/Quests",
    name: "QuestSelection",
    component: QuestSelection,
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
  },
  {
    path: "/Quest/:id",
    name: "Quest",
    component: Quest,
  },
];

const router = new VueRouter({
  routes,
});

export default router;
