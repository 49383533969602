import axios from "axios";
import parser from "ua-parser-js";
import store from "@/store";

const strapi_url = process.env.VUE_APP_STRAPI || "https://api.baccaratquest.fr";

export async function get_quests(questid) {
  const resp = await axios.get(`${strapi_url}/quests?_locale=${questid}`);
  return resp.data;
}

function get_user() {
  let guid = store.state.save.guid;
  if (!guid) guid = store.commit("NEWGUID");
  const ua = parser();
  return {
    guid: store.state.save.guid,
    language: navigator.language,
    browser: ua.browser.name,
    os: ua.os.name,
  };
}

export async function send_event(event) {
  await axios.post(`${strapi_url}/events`, {
    ...event,
    user: get_user(),
  });
}
