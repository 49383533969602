import Vue from "vue";
import { v4 as uuidv4 } from "uuid";
import { send_event } from "@/api/strapi";

const state = {
  guid: null,
  progress: {},
  note: {},
};

const mutations = {
  START_QUEST(state, quest) {
    Vue.set(state.progress, quest.id, 0);
    console.log("start quest " + quest.name);
    send_event({
      type: "start_quest",
      quest: {
        name: quest.name,
        language: quest.locale,
      },
    });
  },
  NEXTSTEP(state, quest) {
    send_event({
      type: "finish_step",
      quest: {
        name: quest.name,
        language: quest.locale,
      },
      etape: state.progress[quest.id],
    });
    state.progress[quest.id]++;
  },
  NEWGUID(state) {
    return (state.guid = uuidv4());
  },
  RATE(state, payload) {
    send_event({
      type: "rate",
      quest: {
        name: payload.quest.name,
        language: payload.quest.locale,
      },
      note: payload.note,
    });
    state.note[payload.quest.id] = payload.note;
  },
};

const getters = {
  step_idx: (state, getters, rootState) =>
    state.progress[rootState.current.quest],
  progress: (state, getters, rootState) => (quest) => {
    quest = quest ? quest : rootState.current.quest;
    return state.progress[quest]
      ? Math.round(
          (state.progress[quest] / rootState.quest.list[quest].steps.length) *
            100
        )
      : 0;
  },
  note: (state, getters, rootState) => state.note[rootState.current.quest],
};

export default {
  state,
  mutations,
  getters,
};
