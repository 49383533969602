<template>
  <div>
    <v-divider />
    <div class="text-h6 my-3 text-center">{{ ui("quizz") }}</div>
    <v-form @submit.prevent="check">
      <div class="text-center my-3" v-html="quizz.question" />
      <v-text-field
        label="Inscrivez ici votre réponse"
        placeholder="Placeholder"
        v-model="response"
        :error-messages="errorMessages"
        :disabled="tmpsuccess"
        solo-inverted
        required
      ></v-text-field>
      <v-row justify="center" class="my-3">
        <v-btn color="blue darken-2" v-if="!tmpsuccess" rounded @click="check">
          {{ ui("check") }}
        </v-btn>
      </v-row>
    </v-form>
    <div v-if="tmpsuccess">
      <v-row justify="center" align="center" class="my-3">
        <v-divider style="border: solid 2px green" />
        <div class="font-weight-black ma-3">{{ ui("bravo") }}</div>
        <v-divider style="border: solid 2px green" />
      </v-row>
      <div class="text-center my-3 marked" v-html="marked(quizz.success_msg)" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Quizz",
  props: {
    quizz: Object,
    success: Boolean,
  },
  data() {
    return {
      response: null,
      errorMessages: null,
      tmpsuccess: this.success,
    };
  },
  computed: {
    ...mapGetters({
      steps: "steps",
      current_step_idx: "step_idx",
    }),
    step: function () {
      return this.steps[this.step_idx];
    },
  },
  methods: {
    check() {
      if (this.$store.state.debug.disableQuizzCheck) {
        this.errorMessages = null;
        this.tmpsuccess = true;
        this.$emit("success");
        return;
      }
      if (!this.response) return;
      if (
        this.response.toLowerCase().replace(/[^\w]/gi, "") ==
        this.quizz.answer.toLowerCase().replace(/[^\w]/gi, "")
      ) {
        this.errorMessages = null;
        this.tmpsuccess = true;
        this.$emit("success");
      } else {
        this.errorMessages = this.ui("wrong");
      }
    },
  },
  mounted() {
    if (this.success) this.response = this.quizz.answer;
  },
};
</script>
