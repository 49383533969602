const state = {
  value: "fr",
  ui: require("@/assets/ui.json"),
};

const mutations = {
  CHANGE_LOCALE(state, locale) {
    state.value = locale;
  },
};

const getters = {
  ui: (state) => (elem) => state.ui[elem] ? state.ui[elem][state.value] : null,
};

const actions = {
  set_locale({ commit, dispatch }, locale) {
    commit("CHANGE_LOCALE", locale);
    dispatch("load_quests");
  },
};

export default {
  state,
  mutations,
  getters,
  actions,
};
