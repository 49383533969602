<template>
  <div class="progress-container ma-2">
    <div class="progress-back">
      <span class="progress-bar" :style="`width: ${value}%`"></span>
    </div>
    <img
      src="/img/DiamantProgression.svg"
      class="progress-end"
      :style="`left: ${value}%`"
    />
  </div>
</template>

<script>
export default {
  name: "ProgressBar",
  props: {
    value: Number,
  },
};
</script>

<style scoped>
.progress-container {
  position: relative;
}

.progress-back {
  width: 100%;
  height: 6px;
  background: #e1e4e8;
  border-radius: 3px;
  overflow: hidden;
}

.progress-bar {
  display: block;
  height: 100%;
  background: linear-gradient(90deg, #ffd33d, #ea4aaa);
}

.progress-end {
  position: absolute;
  position: absolute;
  height: 20px;
  top: -6px;
  margin-left: -10px;
}
</style>
