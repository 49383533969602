import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { Icon } from "leaflet";
import { marked } from "marked";
import { mapGetters } from "vuex";
import "leaflet/dist/leaflet.css";

import vuetify from "./plugins/vuetify";
import "./registerServiceWorker";

Vue.config.productionTip = false;

marked.setOptions({
  baseUrl: "https://api.baccaratquest.fr",
});

Vue.mixin({
  computed: {
    ...mapGetters(["ui"]),
  },
  methods: {
    marked: function (input) {
      return marked(input);
    },
  },
});

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

//https://vue2-leaflet.netlify.app/quickstart/#marker-icons-are-missing
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
  iconUrl: require("leaflet/dist/images/marker-icon.png"),
  shadowUrl: require("leaflet/dist/images/marker-shadow.png"),
});
