<template>
  <v-app dark>
    <debug-panel v-if="$store.state.debug.active" />
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import DebugPanel from "@/components/DebugPanel.vue";
import { send_event } from "@/api/strapi";

export default {
  name: "App",
  components: {
    DebugPanel,
  },
  data: () => ({
    //
  }),
  created: function () {
    send_event({
      type: "visit",
    });
    this.$store.dispatch("load_quests");
  },
};
</script>
<style>
html {
  overflow: hidden !important;
}

@font-face {
  font-family: "Antraste";
  src: local("Antraste"), url(./fonts/Antraste/Antraste.ttf) format("truetype");
}
</style>
