<template>
  <v-dialog
    v-if="step"
    fullscreen
    hide-overlay
    scrollable
    v-model="show"
    transition="dialog-bottom-transition"
  >
    <v-card dark tile class="background">
      <v-card-title class="pa-0">
        <v-toolbar flat tile dense color="transparent">
          <v-toolbar-title class="mx-auto text-center">
            {{ ui("details") }}
          </v-toolbar-title>
          <v-btn fixed right icon>
            <v-icon @click="close">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <div class="text-h6 my-3 text-center">
          {{ ui("step") }} {{ step_idx + 1 }}
        </div>
        <div
          id="step_description"
          class="text-center"
          v-html="marked(step.location_success_msg)"
        />
        <template v-if="step.quizz[0]">
          <quizz
            v-if="step.quizz[0].__component === 'quizz.quizz'"
            :quizz="step.quizz[0]"
            :success="success"
            :key="`quizz-${step.id}`"
            @success="success = true"
          />
          <qcm
            v-if="step.quizz[0].__component === 'quizz.qcm'"
            :quizz="step.quizz[0]"
            :success="success"
            :key="`qcm-${step.id}`"
            @success="success = true"
          />
        </template>
      </v-card-text>

      <v-card-actions style="border-top: solid 1px">
        <v-btn
          text
          :disabled="!steps[step_idx - 1]"
          @click="open(step_idx - 1)"
        >
          <v-icon> mdi-chevron-left </v-icon>
          {{ ui("previous") }}
        </v-btn>
        <v-spacer />
        <div class="text-subtitle text-center">
          {{ ui("step") }} {{ step_idx + 1 }}
        </div>
        <v-spacer />
        <v-btn
          text
          :disabled="
            step_idx == current_step_idx && !success && !!step.quizz[0]
          "
          @click="
            step_idx < current_step_idx - 1 ? open(step_idx + 1) : close()
          "
        >
          {{ ui("next") }}
          <v-icon> mdi-chevron-right </v-icon>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Quizz from "@/components/quizz/quizz.vue";
import Qcm from "@/components/quizz/qcm.vue";

export default {
  name: "StepDetailsDialog",
  components: {
    Quizz,
    Qcm,
  },
  data() {
    return {
      show: false,
      step_idx: null,
      success: false,
    };
  },
  computed: {
    ...mapGetters({
      steps: "steps",
      current_step_idx: "step_idx",
    }),
    step: function () {
      return this.steps[this.step_idx];
    },
  },
  methods: {
    ...mapActions(["nextStep"]),
    open: function (id) {
      this.show = true;
      this.step_idx = id;
      this.success = this.step_idx < this.current_step_idx;
    },
    close: function () {
      if (
        this.step_idx == this.current_step_idx &&
        (this.success || !this.step.quizz[0])
      ) {
        //C'est l'étape en cours et il a un quizz et que celui-ci est un success, on passe à l'étape suivant
        this.nextStep();
      }
      // Puis on ferme
      this.show = false;
    },
  },
};
</script>

<style>
#step_description img {
  max-width: 100%;
  display: flex;
  margin: auto;
}

.background {
  background: url("/img/Background.svg");
  background-size: cover;
  background-position: center center;
}
</style>
