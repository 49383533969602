import { send_event } from "@/api/strapi";

const state = {
  quest: 0,
  end: false,
  start: true,
};

const mutations = {
  INIT(state, quest) {
    state.quest = +quest;
    state.end = false;
    state.start = true;
  },
  LOAD_QUEST(state, payload) {
    state.quest = +payload.quest;
    state.end = payload.end;
    state.start = true;
  },
  END(state, quest) {
    state.end = true;
    send_event({
      type: "end_quest",
      quest: {
        name: quest.name,
        language: quest.locale,
      },
    });
  },
  START(state) {
    state.start = false;
  },
};

const actions = {
  nextStep({ commit, getters }) {
    commit("NEXTSTEP", getters.quest);
    if (!getters.steps[getters.step_idx]) commit("END", getters.quest);
  },
  async loadQuestData({ commit, dispatch, rootState }, quest) {
    if (!rootState.quest.list) await dispatch("load_quests");
    const questdata = rootState.quest.list[quest.id];
    if (!rootState.save.progress[quest.id]) {
      //Pas de données sauvegardés, on commence une nouvelle quête
      commit("START_QUEST", questdata);
      commit("INIT", quest.id);
    } else {
      //Il y a des données, on charge la quête
      commit("LOAD_QUEST", {
        quest: quest.id,
        end: rootState.save.progress[quest.id] == questdata.steps.length,
      });
    }
  },
  tryagain({ commit }, quest) {
    commit("START_QUEST", quest);
    commit("INIT", quest.id);
    commit("START");
  },
  rate({ commit }, payload) {
    if (payload.note) commit("RATE", payload);
  },
};

export default {
  state,
  mutations,
  actions,
};
