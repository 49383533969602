<template>
  <v-sheet dark class="background">
    <div
      class="px-10 py-6 d-flex flex-column"
      style="position: absolute; top: 0; bottom: 0; left: 0; right: 0"
    >
      <img
        class="my-6 mx-auto"
        style="height: 100%; min-height: 110px; max-width: 100%"
        src="/img/Logo.svg"
        alt="Baccarat Quest"
      />
      <div class="mb-6">
        <div class="text-center" v-html="ui('intro')" />
      </div>
      <div class="mb-3">
        <v-select
          :value="locale"
          @change="set_locale($event.value)"
          :items="languages"
          item-text="label"
          item-value="value"
          label="Langue"
          menu-props="dark"
          prepend-inner-icon="mdi-web"
          return-object
          single-line
          solo-inverted
          rounded
        ></v-select>
      </div>
      <div class="mb-6">
        <v-btn block rounded solo outlined to="Quests">
          {{ ui("enterbtn") }}
        </v-btn>
      </div>
      <div class="mb-12 d-flex justify-center">
        <router-link class="white--text" to="About">
          {{ ui("about") }}
        </router-link>
      </div>
    </div>
  </v-sheet>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  name: "Home",
  data: () => ({
    languages: [
      { label: "Français", value: "fr" },
      { label: "English", value: "en" },
      { label: "Deutsch", value: "de" },
    ],
  }),
  computed: {
    ...mapState({
      locale: (state) => state.locale.value,
      quests: (state) => state.quest.list,
    }),
  },
  methods: {
    ...mapActions(["set_locale"]),
  },
};
</script>

<style>
.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: auto;
  background: url("/img/Background.svg");
  background-size: cover;
  background-position: center center;
}
</style>
