const state = {
  active: process.env.VUE_APP_DEBUG === "true",
  disableLocationCheck: false,
  disableQuizzCheck: false,
};

const mutations = {
  SET_DISABLELOCATIONCHECK(state, value) {
    state.disableLocationCheck = value;
  },
  SET_DISABLEQUIZZCHECK(state, value) {
    state.disableQuizzCheck = value;
  },
};

export default {
  state,
  mutations,
};
