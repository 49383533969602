<template>
  <v-dialog
    fullscreen
    hide-overlay
    scrollable
    v-model="show"
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <div
        style="
          position: fixed;
          top: 10px;
          left: 50%;
          transform: translateX(-50%);
          z-index: 1000;
        "
      >
        <v-chip color="primary" small v-show="!show" v-bind="attrs" v-on="on">
          B E T A
        </v-chip>
      </div>
    </template>

    <v-card dark tile class="background">
      <v-card-title class="pa-0">
        <v-toolbar flat tile dense color="transparent">
          <v-toolbar-title class="mx-auto text-center">
            Options
          </v-toolbar-title>
          <v-btn fixed right icon>
            <v-icon @click="show = false">mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-divider />
      <v-card-text>
        <v-container>
          <v-switch
            v-model="disableLocationCheck"
            label="Désactiver le contrôle de la localisation"
          ></v-switch>
          <v-switch
            v-model="disableQuizzCheck"
            label="Désactiver le contrôle des réponses aux quizz"
          ></v-switch>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "DebugPanel",
  data() {
    return {
      show: false,
    };
  },
  computed: {
    disableLocationCheck: {
      get() {
        return this.$store.state.debug.disableLocationCheck;
      },
      set(value) {
        this.$store.commit("SET_DISABLELOCATIONCHECK", value);
      },
    },
    disableQuizzCheck: {
      get() {
        return this.$store.state.debug.disableQuizzCheck;
      },
      set(value) {
        this.$store.commit("SET_DISABLEQUIZZCHECK", value);
      },
    },
  },
};
</script>

<style scoped>
.background {
  background: url("/img/Background.svg");
  background-size: cover;
  background-position: center center;
}
</style>
