<template>
  <v-dialog scrollable v-model="show">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        text
        @click.stop="
          $emit('click');
          show = true;
        "
        :ripple="false"
      >
        {{ ui("skip") }}
        <v-icon right dark> mdi-chevron-right </v-icon>
      </v-btn>
    </template>
    <v-card dark class="rounded-lg">
      <div class="ma-3">{{ ui("skip_confirm") }}</div>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="show = false">
          {{ ui("cancel") }}
        </v-btn>
        <v-btn text @click="skipStep"> {{ ui("confirm") }} </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "SkipStepDialog",
  data() {
    return {
      show: false,
    };
  },
  methods: {
    skipStep: function () {
      this.$store.dispatch("nextStep");
      this.show = false;
    },
  },
};
</script>
