<template>
  <v-dialog scrollable v-model="show">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-bind="attrs"
        v-on="on"
        class="mx-2"
        elevation="0"
        fab
        dark
        small
        color="red darken-2"
      >
        <img style="height: 24px" :src="`/img/IconEtape.svg`" />
      </v-btn>
    </template>
    <v-card dark class="rounded-lg">
      <v-toolbar flat color="transparent" extension-height="30px" dense>
        <v-spacer />
        <v-toolbar-title class="text-uppercase">
          {{ ui("yourprogress") }}
        </v-toolbar-title>
        <v-spacer />
        <v-btn @click="show = false" icon>
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <template v-slot:extension>
          <progress-bar style="width: 100%" :value="progress()" />
        </template>
      </v-toolbar>
      <v-sheet class="overflow-y-auto">
        <v-list class="pt-0">
          <template v-for="(step, idx) in steps">
            <v-divider :key="`divider-${idx}`" />
            <v-list-item
              :key="`item-${idx}`"
              :disabled="idx > current_step_idx"
              @click="open(idx)"
            >
              <v-list-item-icon>
                <img
                  v-if="idx == current_step_idx"
                  style="height: 24px"
                  :src="`/img/EtapeEnCours.svg`"
                />
                <img
                  v-else-if="idx < current_step_idx"
                  style="height: 24px"
                  :src="`/img/EtapeRealise.svg`"
                />
                <img v-else style="height: 24px" :src="`/img/Cadenas.svg`" />
              </v-list-item-icon>

              <v-list-item-content>
                <v-list-item-title>
                  <span v-if="idx < current_step_idx">
                    {{ step.title }}
                  </span>
                  <span v-else> {{ ui("step") }} {{ idx + 1 }} </span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action v-if="idx == current_step_idx">
                <skip-step-dialog @click="show = false" />
              </v-list-item-action>
            </v-list-item>
          </template>
        </v-list>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from "vuex";
import ProgressBar from "@/components/ProgressBar.vue";
import SkipStepDialog from "@/components/SkipStepDialog.vue";

export default {
  name: "StepListDialog",
  components: {
    ProgressBar,
    SkipStepDialog,
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    ...mapGetters({
      steps: "steps",
      current_step_idx: "step_idx",
      progress: "progress",
    }),
  },
  methods: {
    open: function (idx) {
      this.show = false;
      if (idx < this.current_step_idx) this.$emit("click", idx);
    },
  },
};
</script>
