<template>
  <div v-if="quest">
    <v-container v-if="denied" class="background" fill-height>
      <div>
        <v-alert
          text
          type="error"
          elevation="2"
          class="mb-5"
          v-html="ui('geoerror')"
        >
        </v-alert>
        <div class="d-flex justify-center">
          <v-btn @click="askPermission" rounded>{{ ui("retry") }}</v-btn>
        </div>
      </div>
    </v-container>
    <template v-else-if="end">
      <v-sheet dark class="background">
        <div
          class="pa-3 d-flex flex-column"
          style="position: fixed; top: 0; bottom: 0; left: 0; right: 0"
        >
          <img
            class="my-6 mx-auto"
            style="max-width: 110px"
            src="/img/Logo.svg"
            alt="Baccarat Quest"
          />
          <div
            class="text-center text-h4 text-uppercase"
            v-html="ui('congratulation')"
          />
          <div
            class="text-center text-h6 text-uppercase font-weight-black"
            v-html="quest.name"
          />
          <div class="my-3 text-center text-caption">
            {{ ui("level") }} {{ quest.level }}
          </div>
          <div class="my-3 text-center text-subtitle font-weight-bold">
            {{ ui("finished") }}
          </div>
          <div
            class="text-center marked flex-grow-1"
            style="overflow: auto"
            v-html="marked(quest.end_description)"
          />
          <div class="my-3 text-center text-subtitle font-weight-bold">
            {{ ui("rate_us") }}
          </div>
          <v-row justify="center">
            <v-rating
              half-increments
              hover
              background-color="#bf9b30"
              color="#ffbf00"
              length="5"
              size="32"
              v-model="current_note"
            ></v-rating>
          </v-row>
          <v-row class="my-3" justify="space-around">
            <v-btn
              rounded
              light
              @click="
                rate({
                  quest,
                  note: current_note,
                });
                $router.push('/Quests');
              "
            >
              {{ ui("newquest") }}
            </v-btn>
            <v-btn
              @click="
                rate({
                  quest,
                  note: current_note,
                });
                tryagain(quest);
              "
              rounded
              outlined
            >
              <v-icon left> mdi-reload </v-icon>
              {{ ui("restart") }}
            </v-btn>
          </v-row>
        </div>
      </v-sheet>
    </template>
    <template v-else-if="start">
      <v-sheet dark class="background">
        <div class="pa-3 d-flex flex-column">
          <v-btn class="mt-6" absolute left icon dark to="/Quests">
            <v-icon dark> mdi-arrow-left </v-icon>
          </v-btn>
          <img
            class="my-6 mx-auto"
            style="max-width: 110px"
            src="/img/Logo.svg"
            alt="Baccarat Quest"
          />
          <div
            class="text-center text-h6 text-uppercase font-weight-black"
            v-html="quest.name"
          />
          <div class="mb-6 text-center text-caption">
            {{ ui("level") }} {{ quest.level }}
          </div>
          <l-map
            ref="map"
            style="min-height: 150px"
            class="mb-6"
            :zoom="zoom"
            :center="quest.start_location"
          >
            <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
            <l-marker :lat-lng="quest.start_location">
              <l-icon :icon-size="[40, 40]" :icon-anchor="[20, 40]">
                <div class="icon-text text-uppercase">Start</div>
                <img src="/img/marqueur.svg" />
              </l-icon>
            </l-marker>
          </l-map>
          <div
            class="text-center marked"
            v-html="marked(quest.start_description)"
          />
          <div>{{ ui("yourprogress") }} : {{ progress() }}%</div>
          <progress-bar :value="progress()" />
          <div class="ma-3 mb-6">
            <v-btn
              elevation="2"
              class="font-weight-bold"
              @click="$store.commit('START')"
              rounded
              light
              block
            >
              {{ step_idx === 0 ? ui("start") : ui("continue") }}
            </v-btn>
            <v-btn
              v-if="step_idx !== 0"
              class="mt-3"
              @click="tryagain(quest)"
              text
              block
            >
              <v-icon left> mdi-reload </v-icon>
              {{ ui("restart2") }}
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </template>
    <template v-else>
      <l-map
        ref="map"
        style="z-index: 0; position: absolute; top: 0; bottom: 0"
        :zoom="zoom"
        :center="quest.start_location"
      >
        <v-locatecontrol
          @ready="startLocalControl"
          :options="locateOptions"
          ref="locatecontrol"
        />
        <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
        <l-marker :lat-lng="quest.start_location">
          <l-icon :icon-size="[40, 40]" :icon-anchor="[20, 40]">
            <div class="icon-text text-uppercase">Start</div>
            <img src="/img/marqueur.svg" />
          </l-icon>
        </l-marker>
        <l-marker
          v-for="(location, index) in steps_locations"
          :key="index"
          :lat-lng="location"
          @click="openStepDetail(index)"
        >
          <l-icon :icon-size="[40, 40]" :icon-anchor="[20, 40]">
            <div class="icon-text text-uppercase">{{ index }}</div>
            <img src="/img/marqueur.svg" />
          </l-icon>
        </l-marker>
      </l-map>
      <v-card
        dark
        tile
        ref="bottomcard"
        :style="`max-height: ${maxHeight}`"
        :height="height"
        class="bottom-card rounded-t-xl d-flex flex-column"
      >
        <v-toolbar
          color="transparent"
          @touchstart="dragStart"
          @dblclick.prevent="resetSize"
          flat
        >
          <div @mousedown="dragStart" class="handle" />
          <v-toolbar-title class="mx-auto">
            {{ ui("guide") }} {{ step_idx + 1 }}
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text
          class="py-0 text-center marked"
          v-html="marked(step.guide)"
        />
        <v-card-actions>
          <v-btn
            class="mx-2"
            elevation="0"
            fab
            dark
            small
            color="blue darken-2"
            to="/Quests"
          >
            <v-icon dark> mdi-chevron-left </v-icon>
          </v-btn>
          <v-spacer />
          <v-btn
            class="ma-3 font-weight-bold"
            @click="nextStep"
            v-if="inRange || disableLocationCheck"
            rounded
            light
          >
            {{ ui("donebtn") }}
          </v-btn>
          <v-spacer />
          <step-list-dialog @click="openStepDetail" />
        </v-card-actions>
      </v-card>
      <step-details-dialog ref="dialog" />
    </template>
  </div>
  <v-container class="background" v-else fill-height>
    <v-progress-circular
      class="mx-auto"
      :size="100"
      :width="5"
      color="white"
      indeterminate
    ></v-progress-circular>
  </v-container>
</template>

<script>
import { mapGetters, mapState, mapActions } from "vuex";
// import L from "leaflet";
import { LMap, LTileLayer, LMarker, LIcon } from "vue2-leaflet";
import Vue2LeafletLocatecontrol from "vue2-leaflet-locatecontrol";
import StepDetailsDialog from "@/components/StepDetailsDialog.vue";
import StepListDialog from "@/components/StepListDialog.vue";
import ProgressBar from "@/components/ProgressBar.vue";

export default {
  name: "Home",
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LIcon,
    "v-locatecontrol": Vue2LeafletLocatecontrol,
    StepDetailsDialog,
    StepListDialog,
    ProgressBar,
  },
  data() {
    return {
      sheet: true,
      dialog: false,
      dialog_step: null,
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution:
        '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      zoom: 16,
      location: null,
      gettingLocation: false,
      errorStr: null,
      denied: false,
      dragging: false,
      startPos: null,
      bottomcardinitialmaxheight: "40vh",
      bottomcardmaxheight: "85vh",
      height: null,
      maxHeight: "40vh",
      originalHeight: null,
      current_note: null,
      locateOptions: {
        position: "topright",
        flyTo: true,
        enableHighAccuracy: true,
        clickBehavior: {
          inView: "setView",
        },
      },
    };
  },
  computed: {
    ...mapState({
      start: (state) => state.current.start,
      end: (state) => state.current.end,
      distAccurate: (state) => state.option.distAccurate,
      distLarge: (state) => state.option.distLarge,
      disableLocationCheck: (state) => state.debug.disableLocationCheck,
    }),
    ...mapGetters([
      "quest",
      "step",
      "steps",
      "step_idx",
      "steps_locations",
      "progress",
      "note",
    ]),
    inRange: function () {
      if (!this.location) return false;
      const a = this.location.coords;
      const b = this.step.location;

      const dist = this.distance(a.latitude, a.longitude, b.lat, b.lon);
      const maxdist =
        this.step.location_type === "accurate"
          ? this.distAccurate
          : this.distLarge;
      return dist <= maxdist;
    },
  },
  watch: {
    note: function (val) {
      this.current_note = val;
    },
  },
  methods: {
    ...mapActions(["loadQuestData", "tryagain", "rate"]),
    nextStep: function () {
      this.openStepDetail(this.step_idx);
    },
    async openStepDetail(index) {
      this.$refs.dialog.open(index);
    },
    askPermission() {
      navigator.geolocation.getCurrentPosition(
        () => this.locate(),
        (err) => console.error(err)
      );
    },
    startLocalControl() {
      this.$nextTick(() => {
        // Start le follow de la position directement
        if (this.$refs.locatecontrol)
          this.$refs.locatecontrol.mapObject.start();
      });
    },
    locate() {
      this.$nextTick(() => {
        this.denied = false;
        this.watchId = navigator.geolocation.watchPosition(
          (pos) => {
            this.location = pos;
          },
          (err) => {
            console.error(err);
            this.denied = true;
          },
          {
            enableHighAccuracy: true,
          }
        );
      });
    },
    distance(lat1, lon1, lat2, lon2, unit) {
      //https://www.geodatasource.com/developers/javascript
      if (lat1 == lat2 && lon1 == lon2) {
        return 0;
      } else {
        var radlat1 = (Math.PI * lat1) / 180;
        var radlat2 = (Math.PI * lat2) / 180;
        var theta = lon1 - lon2;
        var radtheta = (Math.PI * theta) / 180;
        var dist =
          Math.sin(radlat1) * Math.sin(radlat2) +
          Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
        if (dist > 1) {
          dist = 1;
        }
        dist = Math.acos(dist);
        dist = (dist * 180) / Math.PI;
        dist = dist * 60 * 1.1515;
        if (unit == "K") {
          dist = dist * 1.609344;
        }
        if (unit == "N") {
          dist = dist * 0.8684;
        }
        return dist;
      }
    },
    dragStart(event) {
      this.dragging = true;
      this.maxHeight = this.bottomcardmaxheight;
      if (event.type == "touchstart") {
        var touch = event.changedTouches[0];
        this.startPos = touch.clientY;
      } else if (event.type == "mousedown") {
        this.startPos = event.clientY;
      }

      this.originalHeight = this.$refs.bottomcard.$el.clientHeight;
      this.height = this.originalHeight + "px";
    },
    stopDrag() {
      this.dragging = false;
    },
    doDrag(event) {
      if (this.dragging) {
        let pos;
        if (event.type == "touchmove") {
          var touch = event.changedTouches[0];
          pos = touch.clientY;
        } else if (event.type == "mousemove") {
          pos = event.clientY;
        }
        const moved = pos - this.startPos;
        this.height = this.originalHeight - moved + "px";
      }
    },
    resetSize() {
      this.height = null;
      this.maxHeight = this.bottomcardinitialmaxheight;
      // https://stackoverflow.com/questions/880512/prevent-text-selection-after-double-click
      if (document.selection && document.selection.empty) {
        document.selection.empty();
      } else if (window.getSelection) {
        var sel = window.getSelection();
        sel.removeAllRanges();
      }
    },
  },
  mounted() {
    this.current_note = this.note;
    this.loadQuestData(this.$route.params);
    window.addEventListener("mouseup", this.stopDrag);
    window.addEventListener("touchend", this.stopDrag);
    window.addEventListener("mousemove", this.doDrag);
    window.addEventListener("touchmove", this.doDrag);
    this.locate();
  },
};
</script>

<style>
@import "https://maxcdn.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css";
#map {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.overlay {
  position: relative;
  z-index: 800;
}

.v-card__text {
  flex-grow: 1;
  overflow: auto;
}

.marked img {
  max-width: 100%;
  display: flex;
  margin: auto;
}

.bottom-card {
  position: fixed !important;
  bottom: 0;
  left: 0;
  right: 0;
  min-height: 130px;
  background: url("/img/Background.svg");
  background-size: cover;
  background-position: center center;
}

.handle {
  position: absolute;
  top: 5px;
  left: 50%;
  transform: translate(-50%, 0);
  border-radius: 5px;
  height: 5px;
  width: 50px;
  background-color: #606165;
  cursor: grab;
}

.icon-text {
  position: absolute;
  top: 6px;
  left: 50%;
  transform: translate(-50%, 0);
  font-family: "Merienda", "Roboto", sans-serif;
  font-weight: 700;
  letter-spacing: 0.2em;
  color: black;
}
</style>
